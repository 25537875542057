<template>
  <div>
    <div class="building-tabs-wrapper">
      <div style="display: flex; gap: 4px">
        <div
          v-for="building in buildings"
          :key="'building-' + building._id"
          @click="clickOnBuildingTab(building)"
          class="building-tab"
          :class="{ 'selected-building': building._id === selectedBuilding._id  }"
        >
          {{
            building.buildingName
              ? building.buildingName
              : building.addressObjects[0].address
          }}
        </div>
      </div>
    </div>
    <div
      v-if="unitsLoading"
      class="loader"
      style="position: absolute; top: 50%; left: 50%"
    ></div>
    <table
      v-show="!unitsLoading"
      id="table-body"
      class="table-units-container"
      :key="tableUpdateKey"
    >
      <thead v-show="!unitsLoading" style="display: table-header-group">
        <tr class="table-units-title-row">
          <th style="max-width: 48px" class="table-units-row-title label12">
            <Checkbox
              @clicked="
                selectedUnits.length !== buildingUnits.length
                  ? (selectedUnits = buildingUnits.map((unit) => unit._id))
                  : (selectedUnits = [])
              "
              :checked="
                selectedUnits.length !== 0 &&
                selectedUnits.length === buildingUnits.length
              "
              :customStyle="'width: 16px; height: 16px; margin: 0'"
            />
          </th>
          <th
            v-for="unitField in developmentUnitFields"
            @click="() => sortUnitsBy(unitField)"
            :style="unitField.style"
            class="table-units-row-title label12"
            :id="'unit-' + unitField.className + '-title'"
            :key="unitField.name"
          >
            <div :class="{ 'bold-title': unitField.sortingStep !== 0 }">
              {{ $t(unitField.name) }}
            </div>
            <img
              :class="{
                'sorting-arrow-up': unitField.sortingStep === 1,
                'sorting-arrow-down': unitField.sortingStep === 2,
                'sorting-arrow-hidden': unitField.sortingStep === 0,
              }"
              src="../../assets/images/direction=up.svg"
              alt=""
              style="width: 16px; height: 16px"
            />
          </th>
        </tr>
      </thead>
      <tbody v-if="!unitsLoading">
        <tr
          class="table-units-row"
          v-for="(unit, index) in buildingUnits"
          :key="'unit-' + unit._id"
        >
          <template
            style="display: flex; flex-direction: row"
            v-if="!unit.isNewDevelopment"
          >
            <td
              class="content table-unit-content"
              style="min-width: 48px; max-width: 48px"
            >
              <Checkbox
                @clicked="
                  !selectedUnits.includes(unit._id)
                    ? selectedUnits.push(unit._id)
                    : (selectedUnits = selectedUnits.filter(
                        (un) => un !== unit._id
                      ))
                "
                :checked="selectedUnits.some((un) => un === unit._id)"
                :customStyle="'width: 16px; height: 16px; margin: 0'"
              />
            </td>

            <td
              v-if="!openedUnitEdit && development.assetClass === 'land'"
              @click="toggleListingPanel(unit.listing)"
              :style="unitNameSize"
              class="content table-unit-content"
              :id="'unit-name-' + index"
              name="unitName"
            >
              <p>{{ unit.name ? unit.name : "-" }}</p>
            </td>
            <td
              :style="{
                maxWidth: developmentUnitFields[1].style.minWidth,
                minWidth: developmentUnitFields[1].style.minWidth,
                display: 'flex',
                alignItems: 'center',
              }"
              id="name"
              v-if="openedUnitEdit && development.assetClass === 'land'"
              :name="
                development.assetClass === 'land' ? 'unitNameLand' : 'unitName'
              "
            >
              <v-text-field
                v-model="unit.name"
                @keydown.enter="$event.target.blur()"
                ref="unitname"
                hide-details
                height="16"
                outlined
                dense
              ></v-text-field>
            </td>

            <td
              v-if="development.assetClass === 'land'"
              @click="toggleListingPanel(unit.listing)"
              class="content table-unit-content"
              :id="'unit-address-' + index"
              name="unitAddress"
            >
              <p v-if="!unit.addressEdit">
                {{ unit.address ? unit.address : "-" }}
              </p>
            </td>

            <td
              @click="toggleListingPanel(unit.listing)"
              v-if="!openedUnitEdit && development.assetClass !== 'land'"
              class="content table-unit-content"
              style="cursor: pointer"
              :id="'unit-apartmentnumber-' + index"
              name="apartmentnumber"
            >
              {{ unit.apartmentNumber ? unit.apartmentNumber : "-" }}
            </td>
            <td
              :style="{
                maxWidth: developmentUnitFields[1].style.minWidth,
                minWidth: developmentUnitFields[1].style.minWidth,
                display: 'flex',
                alignItems: 'center',
              }"
              id="apartmentnumber"
              name="apartmentnumber"
              v-if="openedUnitEdit && development.assetClass !== 'land'"
            >
              <v-text-field
                v-model="unit.apartmentNumber"
                @keydown.enter="$event.target.blur()"
                ref="apartmentnumber"
                hide-details
                height="16"
                outlined
                dense
              ></v-text-field>
            </td>

            <td
              @click="toggleListingPanel(unit.listing)"
              v-if="!openedUnitEdit"
              class="content table-unit-content"
              style="cursor: pointer"
              :id="'unit-size-' + index"
              name="size"
            >
              {{ unit.generalSurfaceArea ? unit.generalSurfaceArea : "-" }}
            </td>
            <td
              :style="{
                maxWidth: developmentUnitFields[3].style.minWidth,
                minWidth: developmentUnitFields[3].style.minWidth,
                display: 'flex',
                alignItems: 'center',
              }"
              id="size"
              name="size"
              v-if="openedUnitEdit"
            >
              <v-text-field
                v-model="unit.generalSurfaceArea"
                @keydown.enter="$event.target.blur()"
                @input="formatNumberInput($event, 'generalSurfaceArea', index, 'unitSize')"
                ref="unitSize"
                :key="valueChanged"
                hide-details
                height="16"
                outlined
                dense
              ></v-text-field>
            </td>
            <td
              @click="toggleListingPanel(unit.listing)"
              v-if="!openedUnitEdit && development.assetClass !== 'land'"
              class="content table-unit-content"
              :id="'unit-floor-' + index"
              name="floor"
              style="cursor: pointer"
            >
              {{ unit.floor ? unit.floor : "-" }}
            </td>
            <td
              :style="{
                maxWidth: developmentUnitFields[1].style.minWidth,
                minWidth: developmentUnitFields[1].style.minWidth,
                display: 'flex',
                alignItems: 'center',
              }"
              id="floor"
              name="floor"
              v-if="openedUnitEdit && development.assetClass !== 'land'"
            >
              <v-text-field
                v-model="unit.floor"
                @keydown.enter="$event.target.blur()"
                ref="unitname"
                hide-details
                height="16"
                outlined
                dense
              ></v-text-field>
            </td>
            <td
              @click="toggleListingPanel(unit.listing)"
              v-if="!openedUnitEdit && development.assetClass !== 'land'"
              class="content table-unit-content"
              :id="'unit-rooms-' + index"
              name="rooms"
              style="cursor: pointer"
            >
              {{ unit.rooms ? unit.rooms : "-" }}
            </td>
            <td
              :style="{
                maxWidth: developmentUnitFields[2].style.minWidth,
                minWidth: developmentUnitFields[2].style.minWidth,
                display: 'flex',
                alignItems: 'center',
              }"
              id="rooms"
              name="rooms"
              v-if="openedUnitEdit && development.assetClass !== 'land'"
            >
              <v-text-field
                v-model="unit.rooms"
                @keydown.enter="$event.target.blur()"
                ref="unitname"
                hide-details
                height="16"
                outlined
                dense
              ></v-text-field>
            </td>
            <td
              @click="toggleListingPanel(unit.listing)"
              v-if="!openedUnitEdit && development.assetClass !== 'land'"
              class="content table-unit-content"
              :id="'unit-storeroom-' + index"
              name="storeroom"
              style="cursor: pointer; overflow: hidden; text-overflow: ellipsis"
            >
              <p>{{ unit.storeRoomNr ? unit.storeRoomNr : "-" }}</p>
            </td>
            <td
              :style="{
                maxWidth: developmentUnitFields[3].style.minWidth,
                minWidth: developmentUnitFields[3].style.minWidth,
                display: 'flex',
                alignItems: 'center',
              }"
              id="storeroom"
              name="storeroom"
              v-if="openedUnitEdit && development.assetClass !== 'land'"
            >
              <v-text-field
                v-model="unit.storeRoomNr"
                @keydown.enter="$event.target.blur()"
                ref="unitname"
                hide-details
                height="16"
                outlined
                dense
              ></v-text-field>
            </td>
            <td
              @click="toggleListingPanel(unit.listing)"
              v-if="!openedUnitEdit && development.assetClass !== 'land'"
              class="content table-unit-content"
              :id="'unit-parkingspot-' + index"
              name="parkingspot"
              style="cursor: pointer; overflow: hidden; text-overflow: ellipsis"
            >
              <p>{{ unit.parkingSpotNr ? unit.parkingSpotNr : "-" }}</p>
            </td>
            <td
              :style="{
                maxWidth: developmentUnitFields[4].style.minWidth,
                minWidth: developmentUnitFields[4].style.minWidth,
                display: 'flex',
                alignItems: 'center',
              }"
              id="parkingspot"
              name="parkingspot"
              v-if="openedUnitEdit && development.assetClass !== 'land'"
            >
              <v-text-field
                v-model="unit.parkingSpotNr"
                @keydown.enter="$event.target.blur()"
                ref="unitname"
                hide-details
                height="16"
                outlined
                dense
              ></v-text-field>
            </td>
            <td
              @click="toggleListingPanel(unit.listing)"
              v-if="!openedUnitEdit"
              class="content table-unit-content"
              :id="'unit-amount-' + index"
              name="amount"
              style="cursor: pointer"
            >
              {{ unit.amount ? $nMapper.price(unit.amount) : "-" }}
            </td>
            <td
              :style="{
                maxWidth: developmentUnitFields[4].style.minWidth,
                minWidth: developmentUnitFields[4].style.minWidth,
                display: 'flex',
                alignItems: 'center',
              }"
              id="amount"
              name="amount"
              v-if="openedUnitEdit"
            >
              <v-text-field
                v-model="unit.amount"
                @keydown.enter="$event.target.blur()"
                ref="unitname"
                hide-details
                height="16"
                outlined
                dense
              ></v-text-field>
            </td>
            <td
              @click="toggleListingPanel(unit.listing)"
              class="content table-unit-content"
              name="sqm"
              id="sqm"
            >
              {{ unit.pricePerSquareMeter ? unit.pricePerSquareMeter : "-" }}
            </td>
            <td
              name="leads"
              :id="'unit-leads-' + index"
              class="content table-unit-content"
              style="display: flex; align-items: center"
            >
              <div
                @click.stop="collapseBoard(index, unit.listing)"
                class="listing-title-row"
              >
                <div :id="index + 'button-container'" class="button-leads">
                  <img
                    :id="index + 'down'"
                    src="../../assets/images/collapse-down.svg"
                    alt=""
                    style="height: 13px; width: 13px"
                  />
                  <img
                    style="display: none; height: 13px; width: 13px"
                    :id="index + 'up'"
                    src="../../assets/images/collapse-up.svg"
                    alt=""
                  />

                  <p
                    :key="leadCounterUpdater"
                    v-bind:class="{
                      'leads-open-text': openedLeads.includes(unit.listing._id),
                      'leads-closed-text': !openedLeads.includes(
                        unit.listing._id
                      ),
                    }"
                    class="leads-number-text"
                    :id="index + 'leadsnum'"
                  >
                    {{
                      listingLeads[unit.listing._id] === 1
                        ? listingLeads[unit.listing._id] + ` ${$t("Lead")}`
                        : listingLeads[unit.listing._id] +
                          ` ${$t("NumerableLeads")}`
                    }}
                  </p>
                </div>
              </div>
            </td>
            <td
              name="status"
              v-if="unit.status"
              :id="'unit-status-' + index"
              class="content table-unit-content"
              style="display: flex; align-items: center"
            >
              <v-row class="listing-status-button-active">
                <p
                  @click.stop="openStatusDropdown(unit, index)"
                  :id="'statusLabel' + index"
                  class="statusLabel content-bold"
                  v-bind:class="{
                    'active-label ': unit.status === 'free',
                    'booked-label': unit.status === 'booked',
                    'sold-label ':
                      unit.status === 'sold' || unit.status === 'rented',
                  }"
                >
                  {{ status(unit.status) }}
                </p>

                <v-tooltip bottom right :key="bookingUpdater">
                  <template v-slot:activator="{ on, attrs }">
                    <p
                      v-bind="attrs"
                      v-on="on"
                      v-if="unit.status === 'booked'"
                      class="booked-label booked-info-label"
                    >
                      <img
                        style="filter: invert(1)"
                        src="../../assets/images/info.svg"
                        alt=""
                        @click.stop="openBookingDetailsModal(unit)"
                      />
                    </p>
                  </template>
                  <span>
                    {{ $t("BookingDetails") }}
                  </span>
                </v-tooltip>
              </v-row>
            </td>
            <td
              v-if="!unit.status"
              :id="'unit-status-' + index"
              class="content table-unit-content"
              style="display: flex; align-items: center"
              name="status"
            >
              -
            </td>

            <td
              v-if="!unit.bookedUntil"
              :id="'unit-status-' + index"
              class="content table-unit-content"
              style="display: flex; align-items: center"
              name="bookedUntil"
            >
              -
            </td>

            <td
              v-if="unit.bookedUntil"
              class="content table-unit-content"
              id="bookedUntil"
              name="bookedUntil"
            >
              {{ unit.staus === 'booked' ? formatDate(unit.bookedUntil) : "-" }}
            </td>
            <td
              name="isPublic"
              class="content table-unit-content"
              id="isPublic"
            >
              <BaseToggle
                v-show="development.status !== 'archived'"
                style=""
                :value="unit.listing.isPublic"
                @change="(val) => toggleListingPublic(val, unit.listing._id)"
              />
            </td>
            <td
              class="content table-unit-content"
              id="unitDetails"
              name="unitDetails"
              @click="$emit('unitClicked', unit)"
            >
              <p class="link">{{ $t("UnitDetails") }}</p>
            </td>
            <td
              v-if="development.assetType !== 'LeaseOut'"
              class="content table-unit-content"
              id="lawOfObligations"
              name="lawOfObligations"
            >
              <date-pick
                v-model="unit.lawOfObligations"
                :format="'DD.MM.YYYY'"
                @input="updateUnit(unit)"
              >
                <template v-slot:default="{ toggle }">
                  <div @click.stop="toggle" style="width: 100%; height: 100%">
                    <p v-if="!unit.lawOfObligations" class="content-normal">
                      -
                    </p>
                    <p v-if="unit.lawOfObligations" class="content-normal">
                      {{ unit.lawOfObligations }}
                    </p>
                  </div>
                </template>
              </date-pick>
            </td>
            <td
              v-if="development.assetType !== 'LeaseOut'"
              class="content table-unit-content"
              id="lawOfProperty"
              name="lawOfProperty"
            >
              <date-pick
                v-model="unit.lawOfProperty"
                :format="'DD.MM.YYYY'"
                @input="updateUnit(unit)"
              >
                <template v-slot:default="{ toggle }">
                  <div @click.stop="toggle" style="width: 100%; height: 100%">
                    <p v-if="!unit.lawOfProperty" class="content-normal">-</p>
                    <p v-if="unit.lawOfProperty" class="content-normal">
                      {{ unit.lawOfProperty }}
                    </p>
                  </div>
                </template>
              </date-pick>
            </td>
            <td
              @click="toggleListingPanel(unit.listing)"
              v-if="!openedUnitEdit"
              class="content table-unit-content"
              :id="'unit-amount_paid-' + index"
              name="amount_paid"
              style="cursor: pointer"
            >
              {{ unit.amount_paid ? $nMapper.price(unit.amount_paid) : "-" }}
            </td>
            <td
              :style="{
                maxWidth: developmentUnitFields[4].style.minWidth,
                minWidth: developmentUnitFields[4].style.minWidth,
                display: 'flex',
                alignItems: 'center',
              }"
              id="amount_paid"
              name="amount_paid"
              v-if="openedUnitEdit"
            >
              <v-text-field
                v-model="unit.amount_paid"
                @keydown.enter="$event.target.blur()"
                ref="unitname"
                hide-details
                height="16"
                outlined
                dense
              ></v-text-field>
            </td>

            <td
              @click="toggleListingPanel(unit.listing)"
              class="content table-unit-content"
              :id="'unit-amount_unpaid-' + index"
              name="amount_unpaid"
              style="cursor: pointer"
            >
              {{
                unit.amount_unpaid ? $nMapper.price(unit.amount_unpaid) : "-"
              }}
            </td>
          </template>
          <td class="kanban-table-row" v-if="unit.isNewDevelopment">
            <LeadsKanban
              :kanban-updater="kanbanUpdater"
              :listing="unit"
              @leadOpened="openKanbanLead"
              @funnelCount="setNumberOfLeads($event)"
              style="cursor: auto; width: 100%"
              @refreshLeads="refreshBoardLeads"
            />
          </td>
        </tr>
      </tbody>
      <div
        class="content no-units"
        v-if="buildingUnits.length === 0 && !unitsLoading"
      >
        {{
          development.assetClass === "land"
            ? $t("AddPlotToStart")
            : $t("AddUnitToStart")
        }}
      </div>
    </table>
    <StatusDropdown
      @closed="statusesOpen = false"
      @statusChanged="changeUnitStatus"
      @open-booking-modal="openBookingDetailsModal(selectedUnit)"
      :units-view="true"
      v-if="statusesOpen"
      :position="position"
      :unit="selectedUnit"
      :developmentId="development._id"
      :buildingId="selectedBuilding._id"
      :multi="false"
    />
    <BookingDetailsModal
      v-if="bookingDetailsModalOpen"
      :opened-listing="selectedUnit.listing"
      @statusChanged="changeUnitStatus"
      :unit="selectedUnit"
      @close-modal="bookingDetailsModalOpen = false"
    >
    </BookingDetailsModal>
    <UnitSidepanel
      :unit-id="clickedUnit._id"
      v-if="unitOpened"
      @closed="toggleUnitPanel"
      @refresh="$emit('refresh')"
    />
    <ListingSidepanel
      @overlayClicked="listingPanelOpen = false"
      v-if="listingPanelOpen"
      :listing-id="clickedListing._id"
      :development="development"
    />
    <ClientSidepanel
      @panelClosed="leadOpen = false"
      :customer-data="clickedLead.leadId"
      v-if="leadOpen"
      :from-project-general="true"
    />
  </div>
</template>

<script>
import moment from "moment";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel.vue";
import StatusDropdown from "@/components/Listings/StatusDropdown";
import UnitSidepanel from "@/components/PropertyDeveloper/Detail/UnitSidepanel";
import axios from "axios";
import LeadsKanban from "@/components/Listings/LeadsKanban";
import BookingDetailsModal from "@/components/Listings/BookingDetailsModal";
import BaseToggle from "@/components/common/BaseToggle";
import DatePick from "vue-date-pick";
import Checkbox from "@/components/common/Checkbox.vue";
import ListingSidepanel from "@/components/Listings/ListingSidepanel.vue";
// import AddressFinder from "@/components/Deals/AddressFinder.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "DevelopmentBuildingsTable",
  components: {
    // Checkbox,
    ClientSidepanel,
    StatusDropdown,
    UnitSidepanel,
    LeadsKanban,
    BookingDetailsModal,
    BaseToggle,
    DatePick,
    Checkbox,
    ListingSidepanel,
    // AddressFinder,
  },
  props: [
    "developmentBuildings",
    "development",
    "clearSelectedUnits",
    "tableRefresher",
    "tableEdit",
    "tableSave",
  ],
  data() {
    return {
      listingPanelOpen: false,
      selectedUnits: [],
      isPickerOpen: false,
      buildings: [],
      unitsLoading: false,
      leadOpen: false,
      clickedLead: null,
      bookingUpdater: 0,
      leadCounterUpdater: 0,
      refreshBoardLeads: 0,
      kanbanUpdater: 0,
      valueChanged: 0,
      orgDetailOpen: false,
      detailPanelOpen: false,
      sidePanelData: null,
      clickedOrg: null,
      selectedUnit: [],
      statusesOpen: false,
      position: {},
      tableUpdateKey: 0,
      selectedBuilding: null,
      buildingUnit: null,
      buildingUnits: [],
      buildingUnitsUnsorted: [],
      selectedBuildingUnits: [],
      openedUnitEdit: null,
      clickedUnit: null,
      clickedListing: null,
      unitOpened: null,
      bookingDetailsModalOpen: false,
      listingLeads: {},
      openedLeads: [],
      developmentUnitFields: [
        {
          name: "Number",
          className: "apartmentnumber",
          sortingStep: 0,
          sortingField: "apartmentNumber",
          style: {
            cursor: "pointer",
            width: "80px",
            "min-width": "80px",
            "max-width": "80px",
          },
        },
        {
          name: "SizeMeter",
          className: "size",
          sortingStep: 0,
          sortingField: "generalSurfaceArea",
          style: {
            cursor: "pointer",
            width: "120px",
            "min-width": "120px",
            "max-width": "120px",
          },
        },
        {
          name: "Floor",
          className: "floor",
          sortingStep: 0,
          sortingField: "floor",
          style: {
            cursor: "pointer",
            width: "72px",
            "min-width": "72px",
            "max-width": "72px",
          },
        },
        {
          name: "Rooms",
          className: "rooms",
          sortingStep: 0,
          sortingField: "rooms",
          style: {
            cursor: "pointer",
            width: "72px",
            "min-width": "72px",
            "max-width": "72px",
          },
        },
        {
          name: "StoreRoomNr",
          className: "storeroom",
          sortingStep: 0,
          sortingField: "storeRoomNr",
          style: {
            cursor: "pointer",
            width: "88px",
            "min-width": "88px",
            "max-width": "88px",
          },
        },
        {
          name: "ParkingSpotNr",
          className: "parkingspot",
          sortingStep: 0,
          sortingField: "parkingSpotNr",
          style: {
            cursor: "pointer",
            width: "88px",
            "min-width": "88px",
            "max-width": "88px",
          },
        },
        {
          name: "PriceEur",
          className: "amount",
          sortingStep: 0,
          sortingField: "amount",
          style: {
            cursor: "pointer",
            width: "128px",
            "min-width": "128px",
            "max-width": "128px",
          },
        },
        {
          name: "PriceSqm",
          className: "pricePerSquareMeter",
          sortingStep: 0,
          sortingField: "pricePerSquareMeter",
          style: {
            cursor: "pointer",
            width: "96px",
            "min-width": "96px",
            "max-width": "96px",
          },
        },
        {
          name: "Leads",
          className: "leads",
          sortingStep: 0,
          sortingField: "leads",
          style: {
            cursor: "pointer",
            width: "132px",
            "min-width": "132px",
            "max-width": "132px",
          },
        },
        {
          name: "Status",
          className: "status",
          sortingStep: 0,
          sortingField: "status",
          style: {
            cursor: "pointer",
            width: "154px",
            "min-width": "154px",
            "max-width": "154px",
          },
        },
        {
          name: "Bron. Kuni",
          className: "bronkuni",
          sortingStep: 0,
          sortingField: "bookedUntil",
          style: {
            cursor: "pointer",
            width: "88px",
            "min-width": "88px",
            "max-width": "88px",
          },
        },
        {
          name: "Public",
          className: "publish-listing",
          sortingStep: 0,
          sortingField: "isPublic",
          style: {
            cursor: "pointer",
            width: "88px",
            "min-width": "88px",
            "max-width": "88px",
          },
        },
        {
          name: "",
          className: "publish-listing",
          sortingStep: 0,
          sortingField: "",
          style: {
            cursor: "pointer",
            width: "128px",
            "min-width": "128px",
            "max-width": "128px",
          },
        },
        {
          name: "VÕL",
          className: "võl",
          sortingStep: 0,
          sortingField: "võl",
          style: {
            cursor: "pointer",
            width: "112px",
            "min-width": "112px",
            "max-width": "112px",
          },
        },
        {
          name: "AÕL",
          className: "aõl",
          sortingStep: 0,
          sortingField: "aõl",
          style: {
            cursor: "pointer",
            width: "112px",
            "min-width": "112px",
            "max-width": "112px",
          },
        },
        {
          name: "Paid",
          className: "paid",
          sortingStep: 0,
          sortingField: "amount_paid",
          style: {
            cursor: "pointer",
            width: "112px",
            "min-width": "112px",
            "max-width": "112px",
          },
        },
        {
          name: "Unpaid",
          className: "unpaid",
          sortingStep: 0,
          sortingField: "amount_unpaid",
          style: {
            cursor: "pointer",
            width: "112px",
            "min-width": "112px",
            "max-width": "112px",
          },
        },
      ],
    };
  },
  watch: {
    "selectedUnits.length"() {
      this.$emit("unitsSelected", this.selectedUnits);
    },
    tableEdit(val) {
      this.openedUnitEdit = val;
    },
    tableSave() {
      this.openedUnitEdit = null;
      this.saveEditedUnits();
    },
    openedUnitEdit(val) {
      if (!val) this.$emit("refresh");
    },
    tableRefresher() {
      this.setupTable(true);
    },
    clearSelectedUnits: function () {
      this.selectedUnits = [];
    },
    selectedBuilding(newVal) {
      this.setSelectedDevelopmentsTab(newVal)
    },
    activeDevelopmentsTab(newVal) {
      if (newVal) {
        this.selectedBuilding = newVal;
      }
    }
  },
  created() {
    if (this.development.assetClass === "land") {
      this.developmentUnitFields.shift();
      const nameField = {
        name: "UnitName",
        className: "name",
        sortingStep: 0,
        sortingField: "name",
        style: {
          cursor: "pointer",
          width: "88px",
          "min-width": "88px",
          "max-width": "88px",
        },
      };
      this.developmentUnitFields.splice(0, 0, nameField);
      const addressField = {
        name: "Address",
        className: "address",
        sortingStep: 0,
        sortingField: "address",
        style: {
          cursor: "pointer",
          width: "236px",
          "min-width": "236px",
          "max-width": "236px",
        },
      };
      this.developmentUnitFields[0].style = {
        cursor: "pointer",
        width: "236px",
        "min-width": "236px",
        "max-width": "236px",
      };
      this.developmentUnitFields.splice(1, 0, addressField);
    }
    this.setupTable();
  },
  mounted() {
    this.kanbanUpdater++;
    if (this.activeDevelopmentsTab) {
      this.selectedBuilding = this.activeDevelopmentsTab;
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", null);
  },
  computed: {
    ...mapGetters([
      "activeDevelopmentsTab",
    ]),
    unitNameSize() {
      if (this.development.assetClass === "land") {
        return "min-width: 236px; max-width: 236px;";
      }
      return "";
    },
    unitsOnly() {
      return this.buildingUnits.filter((unit) => !unit.isNewDevelopment);
    },
    listingsOnly() {
      return this.buildingUnits.filter((unit) => unit.isNewDevelopment);
    },
  },
  methods: {
    ...mapActions(['setSelectedDevelopmentsTab']),
    async setupTable(silent = false) {
      if (this.development.assetClass === "land") {
        for (let field of this.developmentUnitFields) {
          if (
            field.sortingField === "rooms" ||
            field.sortingField === "floor" ||
            field.sortingField === "parkingSpotNr" ||
            field.sortingField === "storeRoomNr"
          ) {
            field.style = "display: none;";
          }
          if (field.sortingField === "name") {
            field.style["min-width"] = "236px";
            field.style["max-width"] = "236px";
          }
        }
      }
      if (this.development.assetType === "LeaseOut") {
        for (let field of this.developmentUnitFields) {
          if (field.sortingField === "võl" || field.sortingField === "aõl") {
            field.style = "display: none;";
          }
        }
      }

      if (this.developmentBuildings && this.developmentBuildings.length) {
        const initialBuilding = this.activeDevelopmentsTab ? this.activeDevelopmentsTab : this.developmentBuildings[0];

        await this.selectBuilding(initialBuilding, silent);
        this.buildings = this.developmentBuildings;
        this.selectedBuilding = initialBuilding;

        this.setSelectedDevelopmentsTab(initialBuilding);
      }
      if (this.activeDevelopmentsTab) {
        this.selectedBuilding = this.activeDevelopmentsTab;
      } else {     
        this.selectedBuilding = this.developmentBuildings[0];
      }
    },
    status(status) {
      switch (status) {
        case "draft":
          return this.$t("Draft");
        case "active":
          return this.$t("Active");
        case "free":
          return this.$t("Free");
        case "archived":
          return this.$t("Archived");
        case "sold":
          if (this.development.assetType === "LeaseOut") {
            return this.$t("Rented");
          } else {
            return this.$t("Sold");
          }

        case "rented":
          return this.$t("Rented");
        case "booked":
          return this.$t("Booked");
      }
    },
    formatNumberInput(value, field, index, ref) {
      let outputValue = String(value);
      var regExp = /[a-zA-Z]/g;
      // if value has , replace with --> .
      if (outputValue.includes(",")) {
        outputValue = outputValue.split(",").join(".");
      }
      // if value has . or any letter --> delete last converted . or letter
      if (outputValue.split(".").length > 2 || regExp.test(outputValue)) {
        outputValue = outputValue.slice(0, -1);
      }
      this.buildingUnits[index][field] = outputValue
      this.valueChanged++
      this.$nextTick(() => {
        this.$refs[ref][index].focus();
      })    
    },
    async updateUnit(unit) {
      await axios.post("/api/development/building/unit", unit);
    },
    prefillAddress(index) {
      this.setAddressObject(this.buildingUnits[index].objects[0]);
    },
    setAddressObject(object, index) {
      this.buildingUnits[index].objects = [];
      this.addressObject = object;
      this.buildingUnits[index].objects.push(this.addressObject);
      console.log("kaks: ", this.form.objects);
    },
    removeAddressObject(index) {
      this.addressObject = null;
      this.buildingUnits[index].objects.pop();
    },
    async toggleListingPublic(val, listingId) {
      await axios.post(
        `/api/development/${this.development._id}/unit/listing/${listingId}/toggle-public`,
        { isPublic: val }
      );
    },
    openBookingDetailsModal(unit) {
      this.selectedUnit = unit;
      this.bookingDetailsModalOpen = true;
    },
    async changeUnitStatus(event) {
      const unitId = event.unitId;
      const status = event.status;
      this.statusesOpen = false;

      let bookingBody = {};
      bookingBody.status = status;
      bookingBody.bookedUntil = event.bookedUntil;
      bookingBody.bookerContact = event.bookerContact
        ? event.bookerContact
        : null;
      bookingBody.bookingType = event.bookingType ? event.bookingType : null;
      bookingBody.bookingComment = event.bookingComment
        ? event.bookingComment
        : null;
      if (event.activeUntil) {
        bookingBody.activeUntil = event.activeUntil;
      }
      await axios.post(
        `/api/development/unit/change-status/${unitId}/${status}`,
        bookingBody
      );

      this.$emit("refresh");
      this.bookingUpdater++;
    },
    async selectBuilding(building = null, silent = false) {
      this.selectedBuilding = building;
      if (!silent) this.unitsLoading = true;

      const response = await axios.post(
        `/api/development/units/${this.development._id}`,
        {
          buildingId: building._id,
        }
      );
      const units = response.data.units;
      this.buildingUnitsUnsorted = units.slice(0);
      this.buildingUnits = units;
      this.selectedBuilding.units = units;

      units.forEach((unit) => {
        if (unit.status === 'active' || unit.status === 'draft') {
          unit.status = "free"
        }
        let listing = unit.listing;
        this.listingLeads[unit.listing._id] = 0;
        let leadsLength = 0;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value, index] of Object.entries(listing.funnels)) {
          if (key === "Matches") {
            continue;
          }

          value.forEach(() => {
            leadsLength++;
          });
        }
        if (listing.suggestionsCount) {
          leadsLength = leadsLength + listing.suggestionsCount;
        }
        this.listingLeads[unit.listing._id] = leadsLength;
      });
      this.kanbanUpdater++;
      this.leadCounterUpdater++;

      this.selectedBuildingUnits = [];
      this.developmentUnitFields.forEach((field) => {
        field.sortingStep = 0;
      });
      if (!silent) this.unitsLoading = false;
      this.kanbanUpdater++;
    },
    setNumberOfLeads(event) {
      this.listingLeads[event.listingId] = event.leadsCount;
      //this.numberOfLeads(this.buildingUnits[index].listing);
      this.leadCounterUpdater++;
    },
    numberOfLeads(listing) {
      if (listing.leadsCount) {
        if (listing.leadsCount === 1) {
          return listing.leadsCount + ` ${this.$t("Lead")}`;
        } else {
          return listing.leadsCount + ` ${this.$t("NumerableLeads")}`;
        }
      }

      let leadsLength = 0;
      // eslint-disable-next-line no-unused-vars
      for (const [key, value, index] of Object.entries(listing.funnels)) {
        if (key === "Matches") {
          continue;
        }

        value.forEach(() => {
          leadsLength++;
        });
      }
      if (listing.suggestionsCount) {
        leadsLength = leadsLength + listing.suggestionsCount;
      }

      if (leadsLength === 1) {
        return leadsLength + ` ${this.$t("Lead")}`;
      } else {
        return leadsLength + ` ${this.$t("NumerableLeads")}`;
      }
    },
    collapseBoard(index, object) {
      //let rowHeight = document.getElementById("collapsePart");
      let arrowUp = document.getElementById(index + "up");
      let arrowDown = document.getElementById(index + "down");
      let buttonContainer = document.getElementById(index + "button-container");
      let leadsCount = document.getElementById(index + "leadsnum");

      let isAlreadyOpen = false;
      if (
        this.buildingUnits[index + 1] &&
        this.buildingUnits[index + 1].isNewDevelopment
      ) {
        isAlreadyOpen = true;
      }
      if (!isAlreadyOpen) {
        this.buildingUnits.splice(index + 1, 0, object);

        buttonContainer.style.backgroundColor = "#000000";
        buttonContainer.style.borderColor = "#000000";
        leadsCount.classList.add("leads-open");
        leadsCount.style =
          "filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(103%) contrast(102%);";
        leadsCount.style.display = "flex";
        leadsCount.style.alignItems = "center";
        leadsCount.style.textAlign = "center";
        arrowUp.style =
          "filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(103%) contrast(102%);";
        arrowUp.style.display = "block";
        arrowUp.style.width = "13px";
        arrowUp.style.width = "13px";
        arrowDown.style.display = "none";
        this.openedLeads.push(object._id);
        //this.updateKanban();
      } else {
        //this.usableListings[index].kanbanOpen = false;
        arrowUp.style.display = "none";
        buttonContainer.style.backgroundColor = "#ffffff";
        buttonContainer.style.borderColor = "#E6E8EC";
        buttonContainer.style.border = "1px solid #E6E8EC !important";
        arrowDown.style =
          "filter: invert(0%) sepia(2%) saturate(7467%) hue-rotate(278deg) brightness(105%) contrast(101%);";
        leadsCount.style =
          "filter: invert(0%) sepia(2%) saturate(7467%) hue-rotate(278deg) brightness(105%) contrast(101%);";
        leadsCount.style.display = "flex";
        leadsCount.style.alignItems = "center";
        leadsCount.style.textAlign = "center";
        arrowDown.style.display = "block";
        arrowDown.style.width = "13px";
        arrowDown.style.width = "13px";
        this.buildingUnits.splice(index + 1, 1);
        this.openedLeads = this.openedLeads.filter(
          (open) => open !== object._id
        );

        //this.usableListings[index].kanbanOpen = false;

        //this.updateKanban();
      }
    },

    openKanbanLead(lead) {
      if (lead.module && lead.module === "projects") {
        this.$router.push({
          name: "DealDetail",
          params: { id: lead.leadId },
        });
      } else {
        this.leadOpen = true;
        this.clickedLead = lead;
      }
    },
    toggleUnitPanel(unit) {
      this.clickedUnit = unit;
      this.unitOpened = !this.unitOpened;
    },
    toggleListingPanel(listing) {
      this.clickedListing = listing;
      this.listingPanelOpen = !this.listingPanelOpen;
    },
    selectAllBuildingUnits() {
      if (this.selectedBuildingUnits.length !== this.buildingUnits.length) {
        this.buildingUnits.forEach((unit) => {
          this.selectedBuildingUnits.push(unit._id);
        });
        //remove duplicates
        this.selectedBuildingUnits = [...new Set(this.selectedBuildingUnits)];
      } else {
        this.selectedBuildingUnits = [];
      }
      this.$emit("selectedUnits", this.selectedBuildingUnits);
    },
    selectBuildingUnit(unit) {
      if (this.selectedBuildingUnits.includes(unit._id)) {
        this.selectedBuildingUnits = this.selectedBuildingUnits.filter(
          (selectedId) => selectedId !== unit._id
        );
      } else {
        this.selectedBuildingUnits.push(unit._id);
      }
      this.$emit("selectedUnits", this.selectedBuildingUnits);
    },
    openStatusDropdown(unit, index) {
      let button = document.getElementById("statusLabel" + index);
      const rect = button.getBoundingClientRect();
      this.position.right = window.innerWidth - rect.right - 70;
      this.position.top = rect.top + window.scrollY - 150;
      this.selectedUnit = unit;
      this.statusesOpen = true;
    },
    sortUnitsBy(unitField) {
      if (!unitField.sortingField) return;
      unitField.sortingStep++;
      this.developmentUnitFields.forEach((field) => {
        if (field.sortingStep === 0) {
          return;
        }
        if (
          field.sortingStep > 0 &&
          field.sortingField !== unitField.sortingField
        ) {
          field.sortingStep = 0;
        }
      });
      if (unitField.sortingStep === 1) {
        if (unitField.sortingField === "tenant") {
          this.sortUnitsByContractField("contactPerson");
        } else {
          this.sortUnitsByFieldName(unitField);
        }
        if (this.buildingUnits === this.buildingUnitsUnsorted) {
          unitField.sortingStep = 0;
        }
        setTimeout(() => this.tableUpdateKey++, 100);
        return;
      }
      if (unitField.sortingStep === 2) {
        this.buildingUnits.reverse();
        setTimeout(() => this.tableUpdateKey++, 100);
        return;
      }
      if (unitField.sortingStep > 2) {
        this.buildingUnits = [...this.buildingUnitsUnsorted];
        unitField.sortingStep = 0;
      }
      setTimeout(() => this.tableUpdateKey++, 100);
    },
    isNumeric(value) {
      return /^-?\d+$/.test(value);
    },
    saveEditedUnits() {
      this.openedUnitEdit = null;
      this.$emit("updateUnits", {
        buildingId: this.selectedBuilding._id,
        units: this.selectedBuilding.units,
      });
      //this.tableUpdateKey++;
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    async clickOnBuildingTab(building) {
      await this.resetSorting();
      await this.selectBuilding(building);
      this.tableUpdateKey++;
      setTimeout(() => {
        this.tableUpdateKey++;
      }, 100);
      this.tableUpdateKey++;
      this.$emit("selectedUnits", this.selectedBuildingUnits);
    },
    resetSorting() {
      this.selectedBuilding.units = [...this.buildingUnitsUnsorted];
      this.developmentUnitFields.forEach((field) => {
        field.sortingStep = 0;
      });
    },
    sortUnitsByFieldName(unitField) {
      if (unitField.sortingField === "isPublic") {
        this.buildingUnits = this.buildingUnits.sort(
          (a, b) => a.isPublic - b.isPublic
        );
        return;
      }
      this.buildingUnits = this.buildingUnits.sort((a, b) => {
        let aField = a[unitField.sortingField];
        let bField = b[unitField.sortingField];

        if (unitField.sortingField === "isPublic") {
          aField = a[unitField.sortingField] ? 1 : 0;
          bField = b[unitField.sortingField] ? 1 : 0;
        }
        if (this.isNumeric(aField) || this.isNumeric(bField)) {
          aField = a[unitField.sortingField]
            ? parseInt(a[unitField.sortingField])
            : 0;
          bField = b[unitField.sortingField]
            ? parseInt(b[unitField.sortingField])
            : 0;
        } else {
          aField = a[unitField.sortingField]
            ? String(a[unitField.sortingField])
            : "";
          bField = b[unitField.sortingField]
            ? String(b[unitField.sortingField])
            : "";
        }
        if (aField < bField) {
          return -1;
        }
        if (aField > bField) {
          return 1;
        }
        return 0;
      });
    },
    sortUnitsByContractField(contractField) {
      this.buildingUnits = this.buildingUnits.sort((a, b) => {
        let aField = a["contract"]
          ? a["contract"].find((contract) => contract.statusCode === 1)
          : null;
        let bField = b["contract"]
          ? b["contract"].find((contract) => contract.statusCode === 1)
          : null;
        if (aField && bField) {
          if (contractField === "contactPerson") {
            aField = aField.contactPerson.company_name
              ? aField.contactPerson.company_name
              : aField.contactPerson.contactName;
            bField = bField.contactPerson.company_name
              ? bField.contactPerson.company_name
              : bField.contactPerson.contactName;
          } else {
            aField = aField[contractField];
            bField = bField[contractField];
            if (this.isNumeric(aField) || this.isNumeric(bField)) {
              aField = parseInt(aField);
              bField = parseInt(bField);
            } else {
              aField = String(aField);
              bField = String(bField);
            }
          }
          if (aField < bField) {
            return -1;
          }
          if (aField > bField) {
            return 1;
          }
          return 0;
        }
        if (aField && !bField) {
          return -1;
        }
        if (!aField && bField) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field {
  max-width: 70% !important;
  margin-left: 12px;
}

thead,
tbody {
  display: unset;
}

td {
  ::v-deep .vdpComponent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    :first-child {
      height: unset !important;
    }
    input {
      color: transparent;
    }

    //button {
    //  display: none;
    //}
  }
}

td,
th,
tr {
  width: 100%;
  //overflow-x: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  cursor: pointer;

  p {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

tr {
  width: 100%;
}

td[name="parkingspot"] {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 88px;
  min-width: 88px;
  max-width: 88px;

  p {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}
td[name="storeroom"] {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 88px;
  min-width: 88px;
  max-width: 88px;

  p {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

td[name="unitName"] {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 88px;
  min-width: 88px;
  max-width: 88px;

  p {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    text-decoration: underline;
  }
}

td[name="apartmentnumber"] {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
  min-width: 80px;
  max-width: 80px;

  p {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    text-decoration: underline;
  }
}

td[name="unitNameLand"] {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 236px;
  min-width: 236px;
  max-width: 236px;

  p {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    text-decoration: underline;
  }
}

td[name="unitAddress"] {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 236px;
  min-width: 236px;
  max-width: 236px;

  p {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

td[name="estateType"] {
  width: 96px;
  min-width: 96px;
  max-width: 96px;
}

td[name="size"] {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

td[name="floor"] {
  width: 72px;
  min-width: 72px;
  max-width: 72px;
}

td[name="rooms"] {
  width: 72px;
  min-width: 72px;
  max-width: 72px;
}

td[name="amount"] {
  width: 128px;
  min-width: 128px;
  max-width: 128px;
}
td[name="unitDetails"] {
  width: 128px;
  min-width: 128px;
  max-width: 128px;
}

td[name="sqm"] {
  width: 96px;
  min-width: 96px;
  max-width: 96px;
}

td[name="leads"] {
  width: 132px;
  min-width: 132px;
  max-width: 132px;

  p {
    overflow: initial !important;
  }
}

td[name="status"] {
  width: 154px;
  min-width: 154px;
  max-width: 154px;
}

td[name="bookedUntil"] {
  width: 88px;
  min-width: 88px;
  max-width: 88px;
}

td[name="lawOfObligations"] {
  width: 112px;
  min-width: 112px;
  max-width: 112px;
  cursor: pointer;
}

td[name="lawOfProperty"] {
  width: 112px;
  min-width: 112px;
  max-width: 112px;
  cursor: pointer;
}

td[name="amount_paid"] {
  width: 112px;
  min-width: 112px;
  max-width: 112px;
}

td[name="amount_unpaid"] {
  width: 112px;
  min-width: 112px;
  max-width: 112px;
}

td[name="isPublic"] {
  width: 88px;
  min-width: 88px;
  max-width: 88px;
}

.building-tabs-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  overflow-x: scroll;
}

.no-units {
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leads-open-text {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
    brightness(103%) contrast(102%);
}

.leads-closed-text {
  filter: invert(0%) sepia(2%) saturate(7467%) hue-rotate(278deg)
    brightness(105%) contrast(101%);
}

.statusLabel.booked-label {
  border-radius: 4px 0 0 4px;
}

.booked-info-label {
  border-radius: 0 4px 4px 0;
  border-left: 1px solid #e6e8ec;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  gap: 10px;
  min-height: unset;

  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: black;
    cursor: pointer;
  }
}

::v-deep .add-leads-row-button {
  position: relative !important;
  margin-top: 16px !important;
}

::v-deep .leads-row {
  margin-top: 0px !important;
}

::v-deep .add-leads-row-dropdown {
  position: relative !important;
}

::v-deep div[name="buttons-container"] {
  display: none !important;
}

.button-leads {
  margin-left: -8px;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  min-width: 89px;
  height: 32px;
  gap: 8px;
  white-space: nowrap;
  border-style: solid;
  border: 1px solid #e6e8ec !important;
}

//::v-deep div[name="buttons-container"] {
//  display: none !important;
//}
.listing-title-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.building-tab {
  width: 144px;
  text-align: center;
  cursor: pointer;
  background-color: #f4f5f7;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 12px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  border-left: 2px solid white;
  border-radius: 8px 8px 0 0;
  white-space: nowrap;
}

.selected-building {
  background-color: white;
  cursor: default;
  font-weight: 700;
}

.table-units-container {
  width: 100%;
  border: 2px solid white;
  background-color: white;
  border-radius: 0 8px 8px 8px;
}

.kanban-table-row {
  position: relative;
  width: 100%;
  padding: 8px;
}

.table-units-title-row {
  display: flex;
  height: 40px;
  border-bottom: 1px solid #e6e8ec;
}

.table-units-row {
  display: flex;
  height: fit-content;

  &:first-child {
    //overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;

    p {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.table-units-row:not(:last-child) {
  border-bottom: 1px solid #e6e8ec;
}

.table-units-row-title {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  white-space: nowrap;
}

.table-unit-content {
  padding: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.statusLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  max-width: 40px;
  color: white;
  max-width: fit-content;
  border-radius: 4px;
  cursor: pointer;
}

.sorting-arrow-up {
  display: initial;
}

.output-row {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 12px;
  margin-top: 0px !important;
}

.org-link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.sorting-arrow-down {
  display: initial;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sorting-arrow-hidden {
  display: none;
}

.bold-title {
  font-weight: 700;
}

.gray-img {
  filter: invert(51%) sepia(8%) saturate(118%) hue-rotate(161deg)
    brightness(90%) contrast(93%);
}

.black-img {
  filter: none;
}
</style>
